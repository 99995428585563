import React, { Component, useReducer } from 'react';
import {
    Collapse, 
    Nav, NavItem, NavLink, 
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    Input, InputGroup,
    Button, Row, Col, Form, Container, Label
} from "reactstrap";
import { Helmet } from 'react-helmet'
import {
    Audio,
    BallTriangle,
    Bars,
    Circles,
    Grid,
    Hearts,
    Oval,
    Puff,
    Rings,
    SpinningCircles,
    TailSpin,
    ThreeDots,
} from '@agney/react-loading';
import { Platform_Name } from '../platform_name';
import Instagram from '../images/instagram.png'
import Facebook from '../images/facebook.png'
import Twitter from '../images/twitter.png'
import Logo from '../images/logo.jpeg'

class Footer extends Component{
    constructor(props) { 
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    
    }

    render() {
        return (
            <div  style={{minHeight: '250px', backgroundColor: '#EEECEC'}}>
                <Container>
                    <Row style={{textAlign: 'left', color: ''}}>
                        <Col sm='3'>
                            <br/><br/>
                            <h6 style={{fontWeight: 'bold', color: '#1A0F5B'}}>Get In Touch</h6>
                            <br/>
                            <a href="mailto:admin@firstmedicareers.ac.zw" style={{color: 'inherit'}}>admin@firstmedicareers.ac.zw</a>
                            <br/><br/>
                            <a href="tel:+263788876442" style={{color: 'inherit'}}>+263 788 876 442</a>
                            <br/><br/>
                            <a href="tel:+26552541125" style={{color: 'inherit'}}>+263 5525 41125</a>
                            <br/><br/>
                            <a href="https://www.google.com/maps/place/2+Kings+Avenue,+Kwekwe/@-18.925854979302656,29.80506482888171,17z/data=!4m5!3m4!1s0x1931a5b3de5b7695:0x5c5e0e4fb34aebb0!8m2!3d-18.925854979302656!4d29.80506482888171?entry=ttu" target='_blank' style={{color: 'inherit'}}>
                                2 Kings Avenue, Kurawley Building, Office 14, Kwekwe, Zimbabwe
                            </a>
                        </Col>
                        <Col>
                            <br/><br/>
                            <h6 style={{fontWeight: 'bold', color: '#1A0F5B'}}>Quick Links</h6>
                            <br/>
                            <a href='/' style={{color: 'inherit'}}>
                                Home
                            </a>
                            <br/><br/>
                            <a href='/overview' style={{color: 'inherit'}}>
                                Overview
                            </a>
                            <br/><br/>
                            <a href='/services' style={{color: 'inherit'}}>
                                Services
                            </a>
                            <br/><br/>
                            <a href='/about-us' style={{color: 'inherit'}}>
                                About Us
                            </a>
                            <br/><br/>
                            <a href='/contact-us' style={{color: 'inherit'}}>
                                Contact Us
                            </a>
                        </Col>
                        <Col>
                            <br/><br/>
                            <h6 style={{fontWeight: 'bold', color: '#1A0F5B'}}>Social Media</h6>
                            <br/>
                            <a href='https://www.instagram.com/' target='_blank' style={{color: 'inherit'}}>
                                Instagram
                            </a>
                        </Col>
                        <Col sm='4'>
                            
                        </Col>
                    </Row>
                    <br/>
                    <Row style={{marginTop: '20px'}}>
                        <Col>
                            <Col sm='3' style={{alignItems: 'left', textAlign: 'left', justifyContent: 'left'}}>
                                <Row style={{marginTop: '15px'}}>
                                    <Col xs='5'>
                                        <a href='https://www.instagram.com/' target='_blank'><img src={Instagram} style={{width: '80%'}} /></a>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col style={{fontWeight: 'bold'}}>
                            Copyright 2023 &copy; {Platform_Name}
                        </Col>
                    </Row>
                    <br/>
                </Container>
            </div>
        );
    }
};

export default Footer;