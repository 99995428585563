import React, { Component, useReducer } from 'react';
import {
    Collapse, 
    Nav, NavItem, NavLink, 
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    Input, InputGroup,
    Button, Row, Col, Form, Container, Label
} from "reactstrap";
import { Helmet } from 'react-helmet'
import {
    Audio,
    BallTriangle,
    Bars,
    Circles,
    Grid,
    Hearts,
    Oval,
    Puff,
    Rings,
    SpinningCircles,
    TailSpin,
    ThreeDots,
} from '@agney/react-loading';
import { Platform_Name } from '../platform_name';
import OverviewImage from '../images/overview.jpg'

class Overview extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading: false,
            on_mobile: false
        };

        this.HandleChange = (e) => {
            this.setState({[e.target.name]: e.target.value});
        };
    }

    componentDidMount() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({
                on_mobile: true
            })
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Overview | {Platform_Name}</title>
                    {/* <meta name="description" content="" /> */}
                </Helmet>
                {
                    this.state.loading == true
                    ? <div>
                        <br/><br/><br/>
                        <h5 style={{color: '#214CA3'}}>Loading...</h5>
                        <br/><br/><br/>
                        <TailSpin width='180px' style={{color: '#214CA3'}}/>
                    </div>
                    : <div style={{minHeight: '500px'}}>
                        <Container>
                            <Row>
                                <Col sm='6'>
                                    <br/><br/><br/>
                                    <img src={OverviewImage} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                </Col>
                                <Col>
                                    <br/><br/><br/>
                                    <h4 style={{fontWeight: 'bold'}}>
                                        Overview
                                    </h4>
                                    <br/>
                                    <p style={{textAlign: 'left'}}>
                                        {Platform_Name} is a leading provider of short courses in Zimbabwe. We offer a wide range 
                                        of courses in a variety of skills, including nursing, building, butchery, logo design, and 
                                        web design. Our courses are flexible, affordable, and globally recognized.
                                    </p>
                                    <br/>
                                    <p style={{textAlign: 'left'}}>
                                        We are committed to providing our students with the skills they need to succeed in the 
                                        workforce. Our courses are taught by industry experts and are rooted in professional rigor. 
                                        We also offer career counseling and job placement assistance to help our students find 
                                        employment after course completion.
                                    </p>
                                </Col>
                            </Row>
                            <br/><br/>
                            <p style={{textAlign: 'left'}}>
                                We are proud to offer our courses to students from all backgrounds. We believe that 
                                everyone has the potential to learn and grow, regardless of their academic qualifications.
                            </p>
                            <br/><br/>
                            <h6 style={{fontWeight: 'bold', textAlign: 'left'}}>
                                Benefits of studying with First Medi-Careers Global:
                            </h6>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                <ul>
                                    <li>Flexible learning options</li><br/>
                                    <li>Affordable tuition</li><br/>
                                    <li>Globally recognized courses</li><br/>
                                    <li>Career counseling and job placement assistance</li><br/>
                                    <li>Financial assistance for eligible students</li>
                                </ul>
                            </p>
                        </Container>
                        <br/><br/><br/>
                    </div>
                }
            </div>
        );
    }

};

export default Overview;