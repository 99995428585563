import React, { Component, useReducer } from 'react';
import {
    Collapse, 
    Nav, NavItem, NavLink, 
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    Input, InputGroup,
    Button, Row, Col, Form, Container, Label
} from "reactstrap";
import axios from 'axios';
import { Helmet } from 'react-helmet'
import {
    Audio,
    BallTriangle,
    Bars,
    Circles,
    Grid,
    Hearts,
    Oval,
    Puff,
    Rings,
    SpinningCircles,
    TailSpin,
    ThreeDots,
} from '@agney/react-loading';
import { Platform_Name } from '../platform_name';

class PageNotFound extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading: false
        };

        this.HandleChange = (e) => {
            this.setState({[e.target.name]: e.target.value});
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Page Not Found | {Platform_Name}</title>
                    {/* <meta name="description" content="" /> */}
                </Helmet>
                {
                    this.state.loading == true
                    ? <div>
                        <br/><br/><br/>
                        <h5 style={{color: '#214CA3'}}>Loading...</h5>
                        <br/><br/><br/>
                        <TailSpin width='180px' style={{color: '#214CA3'}}/>
                    </div>
                    : <div>
                        <Container>
                            <br/>
                            <h1 style={{marginTop: '120px', fontWeight: 'bold'}}>
                                404 - Page Not Found
                            </h1>
                            <h5 style={{marginTop: '80px'}}>
                                The page you're looking for has not been found, please check your link and try again
                            </h5>
                            <br/><br/>
                            <a href='/' style={{color: 'inherit'}}>
                                Click here to visit our homepage instead
                            </a>
                        </Container>
                    </div>
                }
            </div>
        );
    }

};

export default PageNotFound;