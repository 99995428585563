import React, { Component, useReducer } from 'react';
import {
    Collapse, 
    Navbar, Nav, NavbarToggler, NavbarBrand, NavItem, NavLink, 
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    Input, InputGroup,
    Button, Row, Col, Form, Container, Label
} from "reactstrap";
import { Helmet } from 'react-helmet'
import {
    Audio,
    BallTriangle,
    Bars,
    Circles,
    Grid,
    Hearts,
    Oval,
    Puff,
    Rings,
    SpinningCircles,
    TailSpin,
    ThreeDots,
} from '@agney/react-loading';
import { Platform_Name } from '../platform_name';
import Logo from '../images/logo.jpeg'

class NavBar extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            isOpen: false,
            on_mobile: false
        };    
        
        this.HandleChange = (e) =>{
            this.setState({[e.target.name]: e.target.value});
        };

        this.toggle = () => {
            this.setState({
                isOpen: !this.state.isOpen
            });
        };
    }

    componentDidMount() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({
                on_mobile: true
            })
        }
    }

    render() {
        return (
            <Navbar light expand="md" sticky='top' style={{backgroundColor: '#EEECEC'}}>
                <NavbarBrand href="/" style={{marginBottom: '0px', height: '100px', backgroundColor: '', textAlign: 'left', width: '10%'}}>
                    <img src={Logo} style={{width: '100px'}} /> 
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle} style={{backgroundColor: '#EEECEC'}}/>
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {
                            this.state.on_mobile == true
                            ? <>
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>  
                            </>
                            : <>
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>  
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>     
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>    
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>             
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>    
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>     
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>    
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>   
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>     
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>    
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>   
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>     
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>    
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>   
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>     
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>    
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>   
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem>   
                                <NavItem>
                                    <NavLink></NavLink>
                                </NavItem> 
                            </>
                        }   
                        <NavItem>
                            <NavLink href='/' style={{backgroundColor: 'inherit', border: 'none', color: '#1A0F5B', fontWeight: 'bold', fontSize: '15px'}}>
                                HOME
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='/overview' style={{backgroundColor: 'inherit', border: 'none', color: '#1A0F5B', fontWeight: 'bold', fontSize: '15px'}}>
                                OVERVIEW
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='/services' style={{backgroundColor: 'inherit', border: 'none', color: '#1A0F5B', fontWeight: 'bold', fontSize: '15px'}}>
                                SERVICES
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='/about-us' style={{backgroundColor: 'inherit', border: 'none', color: '#1A0F5B', fontWeight: 'bold', fontSize: '15px'}}>
                                ABOUT US
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink></NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='/contact-us' style={{backgroundColor: 'inherit', border: 'none', color: '#1A0F5B', fontWeight: 'bold', fontSize: '15px'}}>
                                CONTACT US
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink></NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }

};

export default NavBar;