import React, { Component, useReducer } from 'react';
import {
    Collapse, 
    Nav, NavItem, NavLink, 
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    Input, InputGroup,
    Button, Row, Col, Form, Container, Label
} from "reactstrap";
import { Helmet } from 'react-helmet'
import {
    Audio,
    BallTriangle,
    Bars,
    Circles,
    Grid,
    Hearts,
    Oval,
    Puff,
    Rings,
    SpinningCircles,
    TailSpin,
    ThreeDots,
} from '@agney/react-loading';
import { Platform_Name } from '../platform_name';
import Jumbotron1 from '../images/jumbotron.jpeg'
import Home1 from '../images/home_1.jpg'
import Home2 from '../images/home_2.jpg'
import Home3 from '../images/home_3.jpg'
import Home4 from '../images/home_4.jpg'
import Home5 from '../images/home_5.jpg'

class Home extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading: false,
            on_mobile: false,
            motto_width: '100%',
            j_large_font: '50px',
            j_small_font: '20px',
            j_small_font_weight: 100,
        };

        this.HandleChange = (e) => {
            this.setState({[e.target.name]: e.target.value});
        };
    }

    componentDidMount() {
        // pc  100 20px j_s_f_w & j_s_f
        // pc  50px 140% j_l_f & m_w
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({
                motto_width: '',
                j_large_font: '35px',
                j_small_font: '12px',
                j_small_font_weight: 'bold',
                on_mobile: true
            })
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Home | {Platform_Name}</title>
                    {/* <meta name="description" content="" /> */}
                </Helmet>
                {
                    this.state.loading == true
                    ? <div>
                        <br/><br/><br/>
                        <h5 style={{color: '#214CA3'}}>Loading...</h5>
                        <br/><br/><br/>
                        <TailSpin width='180px' style={{color: '#214CA3'}}/>
                    </div>
                    : <div>
                        <Row style={{minHeight: '400px', margin: '0px', backgroundColor: '#ACD3F0'}}>
                            <Col sm='5'>
                                <Container>
                                    <h1 style={{color: '#4F64BC', fontWeight: 'bold', marginTop: '100px', textAlign: 'left', fontSize: this.state.j_large_font, width: this.state.motto_width}}>
                                        Welcome to {Platform_Name}
                                    </h1>
                                    <h3 style={{color: '#1A0F5B', marginTop: '50px', textAlign: 'left', fontWeight: this.state.j_small_font_weight, fontSize: this.state.j_small_font}}>
                                        Empowering Rural Communities Through Skills Development
                                    </h3>
                                    <Row style={{marginRight: '0px', marginTop: '70px', textAlign: 'left'}}>
                                        <Col sm='6'>
                                            <Button href='/overview' style={{backgroundColor: '#485B85', color: '#BADDE7', fontWeight: 'bold', border: 'none', width: '180px'}}>
                                                OVERVIEW
                                            </Button>
                                            <br/><br/>
                                        </Col>
                                        <Col sm='6'>
                                            <Button href='/contact-us' style={{backgroundColor: 'inherit', color: '#1A0F5B', fontWeight: 'bold', border: '1px solid #1A0F5B', width: '180px'}}>
                                                CONTACT US
                                            </Button>
                                            <br/><br/><br/><br/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col>
                                <img src={Jumbotron1} style={{width: '100%', marginRight: '0px'}} />
                            </Col>
                        </Row>
                        <Container>
                            <Row style={{margin: '0px', minHeight: '300px'}}>
                                <Col sm='6'>
                                    {
                                        this.state.on_mobile == true
                                        ? <><br/><br/></>
                                        : <><br/><br/><br/><br/></>
                                    }
                                    <img src={Home1} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                </Col>
                                <Col>
                                    {
                                        this.state.on_mobile == true
                                        ? <><br/><br/><br/></>
                                        : <><br/><br/><br/><br/><br/><br/><br/><br/></>
                                    }
                                    <h6 style={{fontWeight: 'bold', color: '#1A0F5B'}}>
                                        Unlock Your Potential with Our Wide Array of Courses
                                    </h6>
                                    <br/><br/>
                                    <p style={{textAlign: "left"}}>
                                        At {Platform_Name}, we are committed to providing high-quality training and 
                                        education to individuals in rural communities across Zimbabwe. Our wide range of short 
                                        courses are designed to equip you with the necessary skills to succeed in various 
                                        industries, including healthcare, construction, agriculture, technology, and more.
                                    </p>
                                    {
                                        this.state.on_mobile == true
                                        ? <></>
                                        : <><br/><br/><br/><br/><br/><br/><br/></>
                                    }
                                </Col>
                            </Row>
                            <Row style={{margin: '0px', minHeight: '300px'}}>
                                {
                                    this.state.on_mobile == true
                                    ? <Col>
                                        <br/><br/>
                                        <img src={Home2} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                    </Col>
                                    : <></>
                                }
                                <Col sm='6'>
                                    {
                                        this.state.on_mobile == true
                                        ? <><br/><br/><br/></>
                                        : <><br/><br/><br/></>
                                    }
                                    <h6 style={{fontWeight: 'bold', color: '#1A0F5B'}}>
                                        Empowering Zimbabwe's Rural Communities
                                    </h6>
                                    <br/><br/>
                                    <p style={{textAlign: "left"}}>
                                        Our primary focus is on Zimbabwe's rural communities. We understand the unique challenges 
                                        they face when it comes to accessing quality education and employment opportunities. 
                                        That's why our courses are designed to meet the specific needs of these communities, 
                                        providing them with the skills they need to thrive in today's competitive job market.
                                    </p>
                                    {
                                        this.state.on_mobile == true
                                        ? <></>
                                        : <><br/><br/><br/><br/><br/><br/><br/><br/></>
                                    }
                                </Col>
                                {
                                    this.state.on_mobile == false
                                    ? <Col>
                                        <img src={Home2} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                    </Col>
                                    : <></>
                                }
                            </Row>
                            <Row style={{margin: '0px', minHeight: '300px'}}>
                                <Col sm='6'>
                                    {
                                        this.state.on_mobile == true
                                        ? <><br/><br/></>
                                        : <></>
                                    }
                                    <img src={Home3} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                </Col>
                                <Col>
                                    {
                                        this.state.on_mobile == true
                                        ? <><br/><br/><br/></>
                                        : <><br/><br/><br/></>
                                    }
                                    <h6 style={{fontWeight: 'bold', color: '#1A0F5B'}}>
                                        Globally Certified Courses for International Opportunities
                                    </h6>
                                    <br/><br/>
                                    <p style={{textAlign: "left"}}>
                                        All our courses are globally certified, opening up doors for international employment 
                                        opportunities. Once you've successfully completed a course with us, we'll also assist you 
                                        in finding employment overseas. We believe in empowering our students not just with 
                                        knowledge but also with real-world opportunities.
                                    </p>
                                    {
                                        this.state.on_mobile == true
                                        ? <></>
                                        : <><br/><br/><br/><br/><br/><br/><br/><br/><br/></>
                                    }
                                </Col>
                            </Row>
                            <Row style={{margin: '0px', minHeight: '300px'}}>
                                {
                                    this.state.on_mobile == true
                                    ? <Col>
                                        <br/><br/>
                                        <img src={Home4} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                    </Col>
                                    : <></>
                                }
                                <Col sm='6'>
                                    {
                                        this.state.on_mobile == true
                                        ? <><br/><br/><br/></>
                                        : <><br/><br/><br/></>
                                    }
                                    <h6 style={{fontWeight: 'bold', color: '#1A0F5B'}}>
                                        Financial Assistance for Students Settling Abroad
                                    </h6>
                                    <br/><br/>
                                    <p style={{textAlign: "left"}}>
                                        We understand that settling abroad can be financially challenging. That's why we offer 
                                        loans to students who need financial assistance. You can pay back the loan from your 
                                        salary once you've found employment, following the agreed terms and conditions. We're here 
                                        to support you every step of the way, ensuring a smooth transition into your new life 
                                        abroad.
                                    </p>
                                    {
                                        this.state.on_mobile == true
                                        ? <></>
                                        : <><br/><br/><br/><br/><br/><br/><br/><br/></>
                                    }
                                </Col>
                                {
                                    this.state.on_mobile == false
                                    ? <Col>
                                        <img src={Home4} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                    </Col>
                                    : <></>
                                }
                            </Row>
                            <Row style={{margin: '0px', minHeight: '300px'}}>
                                <Col sm='6'>
                                    {
                                        this.state.on_mobile == true
                                        ? <><br/><br/></>
                                        : <></>
                                    }
                                    <img src={Home5} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                </Col>
                                <Col>
                                    {
                                        this.state.on_mobile == true
                                        ? <><br/><br/><br/></>
                                        : <><br/><br/><br/></>
                                    }
                                    <h6 style={{fontWeight: 'bold', color: '#1A0F5B'}}>
                                        Continuously Evolving Courses for Professional Development
                                    </h6>
                                    <br/><br/>
                                    <p style={{textAlign: "left"}}>
                                        We take pride in our commitment to excellence. That's why we regularly review our courses 
                                        to ensure they reflect the latest research evidence and professional teaching practice. 
                                        We also take into account the different national contexts in which our courses are taught, 
                                        ensuring that they meet the highest standards of structured Continuing Professional 
                                        Development.
                                    </p>
                                    {
                                        this.state.on_mobile == true
                                        ? <></>
                                        : <><br/><br/><br/><br/></>
                                    }
                                </Col>
                            </Row>
                            <br/><br/>
                            <p style={{textAlign: "left", color: '#4F64BC', fontWeight: 'bold'}}>
                                Enroll in one of our globally certified courses and gain the skills you need to succeed in today's 
                                competitive job market. Don't let anything hold you back from achieving your dreams - join us 
                                today!
                            </p>
                        </Container>
                    </div>
                }
                <br/><br/><br/>
            </div>
        );
    }

};

export default Home;