import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from 'react-router-dom';
import NavBar from './components/navbar';
import Footer from './components/footer';
import PageNotFound from './components/page_not_found'
import Home from './components/home'
import Overview from './components/overview'
import Services from './components/services'
import AboutUs from './components/about_us'
import ContactUs from './components/contact_us'

class App extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            
        };
    }

    componentDidMount() {

    }

    render() {
        return (
        <div className="App" style={{}}>
            <BrowserRouter>
                <NavBar />
                <Routes>
                    <Route path='/' element={<Home />}/>
                    <Route path='/overview' element={<Overview />}/>
                    <Route path='/services' element={<Services />}/>
                    <Route path='/about-us' element={<AboutUs />}/>
                    <Route path='/contact-us' element={<ContactUs />}/>
                    <Route path="*" element={<PageNotFound />}/>
                </Routes>
                <Footer />
            </BrowserRouter>    
        </div>
        );
    }
}

export default App;