import React, { Component, useReducer } from 'react';
import {
    Collapse, 
    Nav, NavItem, NavLink, 
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    Input, InputGroup,
    Button, Row, Col, Form, Container, Label
} from "reactstrap";
import { Helmet } from 'react-helmet'
import {
    Audio,
    BallTriangle,
    Bars,
    Circles,
    Grid,
    Hearts,
    Oval,
    Puff,
    Rings,
    SpinningCircles,
    TailSpin,
    ThreeDots,
} from '@agney/react-loading';
import { Platform_Name } from '../platform_name';
import * as emailjs from 'emailjs-com';
import ContactUsImage from '../images/contact_us.jpg';

class ContactUs extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading: false,
            on_mobile: false,
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            message: ''
        };

        this.HandleChange = (e) => {
            this.setState({[e.target.name]: e.target.value});
        };

        this.SubmitMessage = (e) => {
            e.preventDefault()
      
            if (this.state.firstname == ''){
                alert('Firstname is required.')
            }else if (this.state.lastname == ''){
                alert('Lastname is required.')
            }else if (this.state.email == ''){
                alert('Email is required.')
            }else if(this.state.phone == ''){
                alert('Phone is required.')
            }else if(this.state.message == ''){
                alert('Message is required.')
            }else{
                let message = 'Message via website form: <br/><br/>' +
                    'Firstname: ' + this.state.firstname + '<br/>' +
                    'Lastname: ' + this.state.lastname + '<br/>' +
                    'Email: ' + this.state.email + '<br/>' +
                    'Phone: ' + this.state.phone + '<br/>' +
                    'Message: ' + this.state.message
        
                let templateParams = {
                    from_name: this.state.firstname + ' ' + this.state.lastname,
                    to_name: {Platform_Name},
                    reply_to: this.state.email,
                    subject: 'Website form submission',
                    message_html: message,
                }
        
                try{
                    emailjs.send(
                    'service_wtne6p1',
                    'template_jd4yf7n',
                    templateParams,
                    'user_GWbUKR3vdLVuDdeyBK5iL'
                    )
                    alert('Message submitted successfully.')
                    window.location.reload()
                }catch(err){
                    alert('Something went wrong, please try again.')
                }
            }
        }
    }

    componentDidMount() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({
                on_mobile: true
            })
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Contact Us | {Platform_Name}</title>
                    {/* <meta name="description" content="" /> */}
                </Helmet>
                {
                    this.state.loading == true
                    ? <div>
                        <br/><br/><br/>
                        <h5 style={{color: '#214CA3'}}>Loading...</h5>
                        <br/><br/><br/>
                        <TailSpin width='180px' style={{color: '#214CA3'}}/>
                    </div>
                    : <div style={{minHeight: '500px'}}>
                        <Container>
                            <Row>
                                <Col sm='6'>
                                    <br/><br/><br/>
                                    <img src={ContactUsImage} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                </Col>
                                <Col>
                                    <br/><br/><br/>
                                    <h4 style={{fontWeight: 'bold'}}>
                                        Contact Us
                                    </h4>
                                    <br/>
                                    <p style={{textAlign: 'left'}}>
                                        Thank you for your interest in {Platform_Name}. We are here to answer any questions you may have.
                                    </p>
                                    <br/>
                                    <h6 style={{fontWeight: 'bold'}}>
                                        Contact Information:
                                    </h6>
                                    <br/>
                                    <Row style={{margin: '0px', textAlign: 'left'}}>
                                        <Col xs='6' style={{fontWeight: 'bold'}}>
                                            Phone:
                                            <br/>
                                            Tel:
                                            <br/>
                                            Email:
                                        </Col>
                                        <Col>
                                            <a href="tel:+263788876442" style={{color: 'inherit'}}>+263 788 876 442</a>
                                            <br/>
                                            <a href="tel:+26552541125" style={{color: 'inherit'}}> +263 5525 41125</a>
                                            <br/>
                                            <a href="mailto:admin@firstmedicareers.ac.zw" style={{color: 'inherit'}}>admin@firstmedicareers.ac.zw</a>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <h6 style={{fontWeight: 'bold'}}>
                                        Office Hours:
                                    </h6>
                                    <br/>
                                    <Row style={{margin: '0px', textAlign: 'left'}}>
                                        <Col xs='6' style={{fontWeight: 'bold'}}>
                                            Monday - Friday: 
                                            <br/>
                                            Saturday:
                                        </Col>
                                        <Col>
                                            9:00 AM - 5:00 PM
                                            <br/>
                                            9:00 AM - 12:00 PM
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br/><br/>
                            <h6 style={{fontWeight: 'bold', textAlign: 'left'}}>
                                Location:
                            </h6>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                {Platform_Name}
                                <br/>
                                2 Kings Avenue, Kurawley Building, Office 14
                                <br/>
                                Kwekwe, Zimbabwe
                            </p>
                            <br/><br/>
                            <p style={{fontWeight: 'bold'}}>
                                Alternatively, you can contact us using the form below.
                            </p>
                            <br/><br/><br/>
                            <Row style={{textAlign: 'left'}}>
                                <Col>
                                    Firstname <span style={{color: 'red'}}>*</span>
                                    <Input type="text" name="firstname" style={{}} placeholder=""
                                    value={this.state.firstname} onChange={this.HandleChange} />
                                </Col>
                                <Col>
                                    Lastname <span style={{color: 'red'}}>*</span>
                                    <Input type="text" name="lastname" style={{}} placeholder=""
                                    value={this.state.lastname} onChange={this.HandleChange} />
                                </Col>
                            </Row>
                            <br/><br/>
                            <Row style={{textAlign: 'left'}}>
                                <Col>
                                    Email <span style={{color: 'red'}}>*</span>
                                    <Input type="text" name="email" style={{}} placeholder=""
                                    value={this.state.email} onChange={this.HandleChange} />
                                </Col>
                                <Col>
                                    Phone <span style={{color: 'red'}}>*</span>
                                    <Input type="text" name="phone" style={{}} placeholder=""
                                    value={this.state.phone} onChange={this.HandleChange} />
                                </Col>
                            </Row>
                            <br/><br/>
                            <Row style={{textAlign: 'left'}}>
                                <Col>
                                    Message <span style={{color: 'red'}}>*</span>
                                    <Input type="textarea" rows='5' name="message" style={{}} placeholder=""
                                    value={this.state.message} onChange={this.HandleChange} />
                                </Col>
                            </Row>
                            <br/>
                            <Button onClick={this.SubmitMessage} 
                                style={{backgroundColor: 'inherit', color: '#1A0F5B', fontWeight: 'bold', border: '1px solid #1A0F5B', width: '180px'}}
                            >
                                Submit
                            </Button>
                        </Container>
                        <br/><br/><br/>
                    </div>
                }
            </div>
        );
    }

};

export default ContactUs;