import React, { Component, useReducer } from 'react';
import {
    Collapse, 
    Nav, NavItem, NavLink, 
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    Input, InputGroup,
    Button, Row, Col, Form, Container, Label
} from "reactstrap";
import { Helmet } from 'react-helmet'
import {
    Audio,
    BallTriangle,
    Bars,
    Circles,
    Grid,
    Hearts,
    Oval,
    Puff,
    Rings,
    SpinningCircles,
    TailSpin,
    ThreeDots,
} from '@agney/react-loading';
import { Platform_Name } from '../platform_name';
import ServicesImage from '../images/services.jpg'

class Services extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading: false,
            on_mobile: false
        };

        this.HandleChange = (e) => {
            this.setState({[e.target.name]: e.target.value});
        };
    }

    componentDidMount() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({
                on_mobile: true
            })
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Services | {Platform_Name}</title>
                    {/* <meta name="description" content="" /> */}
                </Helmet>
                {
                    this.state.loading == true
                    ? <div>
                        <br/><br/><br/>
                        <h5 style={{color: '#214CA3'}}>Loading...</h5>
                        <br/><br/><br/>
                        <TailSpin width='180px' style={{color: '#214CA3'}}/>
                    </div>
                    : <div style={{minHeight: '500px'}}>
                        <Container>
                            <Row>
                                <Col sm='6'>
                                    <br/><br/><br/>
                                    <img src={ServicesImage} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}} />
                                </Col>
                                <Col>
                                    <br/><br/><br/>
                                    <h4 style={{fontWeight: 'bold'}}>
                                        Services
                                    </h4>
                                    <br/>
                                    <p style={{textAlign: 'left'}}>
                                        {Platform_Name} is a leading provider of short courses in Zimbabwe's rural 
                                        communities. We offer a wide range of courses in a variety of skills, including:
                                    </p>
                                    <br/>
                                    <p style={{textAlign: 'left'}}>
                                        <ul>
                                            <li>Nurse aids</li>
                                            <li>Building</li>
                                            <li>Butchery</li>
                                            <li>Logo designing</li>
                                            <li>Web designing</li>
                                            <li>And much more!</li>
                                        </ul>
                                    </p>
                                </Col>
                            </Row>
                            <br/><br/>
                            <p style={{textAlign: 'left'}}>
                                Our courses are designed to be flexible and accessible, so you can enroll at any time and 
                                learn at your own pace.
                            </p>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                All of our courses are created by industry experts and are globally certified. This means 
                                that you can be confident that you are learning the skills that you need to succeed in 
                                your chosen field.
                            </p>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                Once you have completed a course, we will help you find employment overseas. We have a 
                                network of contacts in the international workforce, and we will work with you to find the 
                                perfect job for your skills and experience.
                            </p>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                We are committed to providing our students with the highest quality education. Our courses 
                                are regularly reviewed to ensure that they reflect the latest research and teaching 
                                practices.
                            </p>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                Our mission is to provide our students with the skills they need to succeed in their careers and to improve their 
                                lives.
                            </p>
                            <br/>
                            <p style={{textAlign: 'left', fontWeight: 'bold'}}>
                                Contact us today to learn more about our services!
                            </p>
                        </Container>
                        <br/><br/><br/>
                    </div>
                }
            </div>
        );
    }

};

export default Services;