import React, { Component, useReducer } from 'react';
import {
    Collapse, 
    Nav, NavItem, NavLink, 
    UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, 
    Input, InputGroup,
    Button, Row, Col, Form, Container, Label
} from "reactstrap";
import { Helmet } from 'react-helmet'
import {
    Audio,
    BallTriangle,
    Bars,
    Circles,
    Grid,
    Hearts,
    Oval,
    Puff,
    Rings,
    SpinningCircles,
    TailSpin,
    ThreeDots,
} from '@agney/react-loading';
import { Platform_Name } from '../platform_name';
import AboutUsImage from '../images/about_us.jpg'

class AboutUs extends Component{
    constructor(props) { 
        super(props);
        this.state = {
            loading: false,
            on_mobile: false
        };

        this.HandleChange = (e) => {
            this.setState({[e.target.name]: e.target.value});
        };
    }

    componentDidMount() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            this.setState({
                on_mobile: true
            })
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>About Us | {Platform_Name}</title>
                    {/* <meta name="description" content="" /> */}
                </Helmet>
                {
                    this.state.loading == true
                    ? <div>
                        <br/><br/><br/>
                        <h5 style={{color: '#214CA3'}}>Loading...</h5>
                        <br/><br/><br/>
                        <TailSpin width='180px' style={{color: '#214CA3'}}/>
                    </div>
                    : <div style={{minHeight: '500px'}}>
                        <Container>
                            <Row>
                                <Col sm='6'>
                                    <br/><br/><br/>
                                    <img src={AboutUsImage} style={{width: '100%', border: '1px solid #1A0F5B', borderRadius: '10px'}}/>
                                </Col>
                                <Col>
                                    <br/><br/><br/>
                                    <h4 style={{fontWeight: 'bold'}}>
                                        About Us
                                    </h4>
                                    <br/>
                                    <p style={{textAlign: 'left'}}>
                                        {Platform_Name} is a leading provider of short courses and training in Zimbabwe. 
                                        We offer a wide range of courses in a variety of skills, including nurse aids, building, 
                                        butchery, logo designing, web designing, and more.
                                    </p>
                                    <br/>
                                    <p style={{textAlign: 'left'}}>
                                        Our primary focus is on rural communities, where we believe there is a great need for skills training. We offer 
                                        our courses in a flexible way, so that learners can enroll at any time and work at their own pace.
                                    </p>
                                </Col>
                            </Row>
                            <br/><br/>
                            <p style={{textAlign: 'left'}}>
                                Our courses are created by industry experts and rooted in professional rigor. They reflect 
                                the latest professional development research and are globally certified. This means that 
                                our graduates are eligible for employment overseas.
                            </p>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                We also offer financial assistance to students in need of help settling abroad after they 
                                have completed a course and found employment.
                            </p>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                We review our courses regularly to ensure that they reflect the latest research evidence and 
                                professional teaching practice. We also take account of the different national contexts in which they 
                                are taught.
                            </p>
                            <br/>
                            <p style={{textAlign: 'left'}}>
                                Our mission is to provide high-quality structured Continuing Professional Development (CPD) courses 
                                that help people improve their skills and knowledge, and advance their careers.
                            </p>
                            <br/><br/>
                            <p style={{textAlign: 'left', fontWeight: 'bold'}}>
                                We believe that everyone has the potential to succeed, regardless of their background. We 
                                are committed to providing affordable and accessible training that will help people reach 
                                their full potential. If you are looking to improve your skills and knowledge, or to 
                                advance your career, we encourage you to explore our courses. We are confident that you 
                                will find the right course for you. Thank you for your interest in {Platform_Name}. We 
                                look forward to helping you reach your goals.
                            </p>
                        </Container>
                        <br/><br/><br/>
                    </div>
                }
            </div>
        );
    }

};

export default AboutUs;